import { AllFileData } from '@pp/types/data/allFile'
import { useStaticQuery, graphql } from 'gatsby'

export const useTechStackData = () => {
  const data: AllFileData = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativePath: { regex: "/images/stack_png/" } }) {
          nodes {
            relativePath
            name
            childImageSharp {
              fixed(height: 50) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    `,
  )

  return data
}
