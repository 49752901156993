import { Flex, colors } from '@prontopro/ui-toolkit'
import styled from 'styled-components'

type Props = {
  first?: boolean
  isActive?: boolean
  last?: boolean
}

export const InnerTab = styled(Flex).attrs<Props>(({ first, last }) => ({
  pl: first ? 0 : 16,
  pr: last ? 0 : 16,
  py: 12,
}))<Props>`
  cursor: pointer;
  font-weight: 600;
  color: ${({ isActive }) => (isActive ? colors.darkGreen600 : colors.gray100)};
`
