import { Content } from '@pp/components/Content'
import { Description } from '@pp/components/Description'
import { Hero } from '@pp/components/Hero'
import { Layout } from '@pp/components/Layout'
import { MaxWidth1200 } from '@pp/components/MaxWidth'
import { PostExcerpt } from '@pp/components/PostExcerpt'
import { SEO } from '@pp/components/SEO'
import { TechStack } from '@pp/components/TechStack'
import { usePageData } from '@pp/hooks/usePageData'
import { UnderlinedText } from '@pp/ui/UnderlinedText'
import { colors, Flex, Text } from '@prontopro/ui-toolkit'
import React from 'react'

const Page = () => {
  const data = usePageData()

  return (
    <Layout>
      <SEO
        title="ProntoPro Engineering: Building the future of local services"
        // eslint-disable-next-line max-len
        description="Engineering at ProntoPro is all about building the next default destination platform for people looking to hire local-service professionals."
      />

      <MaxWidth1200>
        <Hero>
          <UnderlinedText color={colors.lightBlue300}>Building the future of local services.</UnderlinedText>
        </Hero>

        <Content>
          <Description />
        </Content>

        <Content>
          <Flex align="center" pt={16}>
            <Flex pb={12}>
              <Text as="h2" kind="headingS" colorName="darkGreen600">
                Our Tech Stack
              </Text>
            </Flex>

            <TechStack />
          </Flex>
        </Content>

        <Content>
          <Flex pt={24} pb={8}>
            <Text as="h2" kind="headingS" colorName="darkGreen600">
              Latest posts
            </Text>
          </Flex>

          {data.posts.nodes.map((node, index) => (
            <PostExcerpt key={node.id} data={node} last={index === data.posts.nodes.length - 1} />
          ))}
        </Content>
      </MaxWidth1200>
    </Layout>
  )
}

export default Page
