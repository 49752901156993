import React, { ReactNode, cloneElement, createContext, isValidElement, useContext, useMemo, useState } from 'react'

type State = [number, React.Dispatch<React.SetStateAction<number>>]

type TabsState = State

const TabsState = createContext<TabsState>([0, () => {}])

type Elements = {
  panels: number
  tabs: number
}

const Elements = createContext<Elements>({ tabs: 0, panels: 0 })

type TabsProps = {
  state?: State
  children: ReactNode
}

export const Tabs = ({ state: outerState, children }: TabsProps) => {
  const innerState = useState(0)
  const elements = useMemo(() => ({ tabs: 0, panels: 0 }), [])

  const state = outerState || innerState

  return (
    <Elements.Provider value={elements}>
      <TabsState.Provider value={state}>{children}</TabsState.Provider>
    </Elements.Provider>
  )
}

export const useTabState = () => {
  const [activeIndex, setActive] = useContext(TabsState)
  const elements = useContext(Elements)

  const tabIndex = useMemo(() => {
    const currentIndex = elements.tabs
    elements.tabs += 1

    return currentIndex
  }, [])

  const onClick = useMemo(() => () => setActive(tabIndex), [])

  const state = useMemo(
    () => ({
      isActive: activeIndex === tabIndex,
      onClick,
    }),
    [activeIndex, onClick, tabIndex],
  )

  return state
}

export const usePanelState = () => {
  const [activeIndex] = useContext(TabsState)
  const elements = useContext(Elements)

  const panelIndex = useMemo(() => {
    const currentIndex = elements.panels
    elements.panels += 1

    return currentIndex
  }, [])

  return panelIndex === activeIndex
}

type TabProps = {
  children: ReactNode
}

export const Tab = ({ children }: TabProps) => {
  const state = useTabState()

  if (typeof children === 'function') {
    return children(state)
  }

  if (isValidElement(children)) {
    return cloneElement(children, state)
  }

  return <>{children}</>
}

type PanelProps = {
  active?: boolean
  children: ReactNode
}

export const Panel = ({ active, children }: PanelProps) => {
  const isActive = usePanelState()

  if (!isActive && !active) {
    return null
  }

  return <>{children}</>
}
