import { Image } from '@pp/components/Image'
import { MaxWidth100Percent } from '@pp/components/MaxWidth'
import { useTechStackData } from '@pp/hooks/useTechStackData'
import { AllFileData } from '@pp/types/data/allFile'
import { Panel, Tab, Tabs } from '@pp/ui/Tabs'
import { Flex } from '@prontopro/ui-toolkit'
import React, { useMemo } from 'react'
import { InnerTab } from './InnerTab'
import { WrapperScrollable } from './WrapperScrollable'

export const TechStack = () => {
  const data = useTechStackData()

  const match = useMemo(
    () => data.allFile.nodes.reduce(
      (accumulator, { childImageSharp, name }) => ({
        ...accumulator,
        [name]: childImageSharp,
      }),
      {} as { [key: string]: AllFileData['allFile']['nodes'][number]['childImageSharp'] },
    ),
    [data],
  )!

  return (
    <Tabs>
      {/* eslint-disable dot-notation */}
      <MaxWidth100Percent pb={12} align="center">
        <WrapperScrollable align="center" direction="row">
          <Tab>
            <InnerTab first>Frontend</InnerTab>
          </Tab>
          <Tab>
            <InnerTab>Backend</InnerTab>
          </Tab>
          <Tab>
            <InnerTab>DevOps</InnerTab>
          </Tab>
          <Tab>
            <InnerTab last>Data</InnerTab>
          </Tab>
        </WrapperScrollable>
      </MaxWidth100Percent>

      <Panel>
        <MaxWidth100Percent align="center">
          <WrapperScrollable align="center" direction="row">
            <Flex pr={32}>
              <Image fixed={match['js'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['ts'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['react'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['redux'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['styled-components'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['jest'].fixed} />
            </Flex>
          </WrapperScrollable>
        </MaxWidth100Percent>
      </Panel>
      <Panel>
        <MaxWidth100Percent align="center">
          <WrapperScrollable align="center" direction="row">
            <Flex pr={32}>
              <Image fixed={match['php'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['go'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['elasticsearch'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['node'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['ts'].fixed} />
            </Flex>
          </WrapperScrollable>
        </MaxWidth100Percent>
      </Panel>
      <Panel>
        <MaxWidth100Percent align="center">
          <WrapperScrollable align="center" direction="row">
            <Flex pr={32}>
              <Image fixed={match['docker'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['kubernetes'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['terraform'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['aws'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['ansible'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['openstack'].fixed} />
            </Flex>
          </WrapperScrollable>
        </MaxWidth100Percent>
      </Panel>
      <Panel>
        <MaxWidth100Percent align="center">
          <WrapperScrollable align="center" direction="row">
            <Flex pr={32}>
              <Image fixed={match['aws'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['scala'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['spark'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['flink'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['kafka'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['airflow'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['snowplow'].fixed} />
            </Flex>
            <Flex pr={32}>
              <Image fixed={match['tensorflow'].fixed} />
            </Flex>
          </WrapperScrollable>
        </MaxWidth100Percent>
      </Panel>
      {/* eslint-enable dot-notation */}
    </Tabs>
  )
}
