import { InternalLink } from '@pp/ui/InternalLink'
import { Flex, Text } from '@prontopro/ui-toolkit'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled(Flex)`
  p {
    margin: 10px 0;
  }
`

export const Description = () => (
  <Wrapper direction="row" justify="space-between" wrap="wrap">
    <Text as="p" kind="bodyM">
      Engineering at ProntoPro is all about building the next default destination platform for people looking to hire
      local-service professionals. This means dealing with complex real-world problems to make choosing the right pro
      for your project as simple as looking something up on Google.
    </Text>
    <Text as="p" kind="bodyM">
      As we keep growing as a company, we constantly experience how conditions change quickly, and what&apos;s working
      well today might not be solid enough for tomorrow. This blog contains some insights that we would like to share as
      they might be helpful to you in your scaling journey.
    </Text>
    <Text as="p" kind="bodyM">
      Does it sound interesting? Then you should probably check out our&nbsp;
      <InternalLink to="https://www.prontopro.it/people/careers">careers page</InternalLink>
      &nbsp;!
    </Text>
  </Wrapper>
)
